import React from "react";
import "./Dashboard.css";
import Vip from "../Vip/Vip";
import Sponsor from "./Sponsor";
import Task from "../Taskbaord/Task";
import { Modal, Button, Row, Col } from "react-bootstrap";
import SmallHeader from "../../Header/SmallHeader";

export default function Dashboard() {
  return (
    <div>
      <div className="page">
        <Task />
        <div
          className="container txp"
          style={{ display: "none", marginTop: "50vh" }}
        >
          <br />
          <br />
          <div style={{ textAlign: "center" }}>
            <p style={{ fontWeight: 600 }}>
              Build your affiliate revenue stream at <br />
              <span style={{ color: "#66dc71" }}>$0 initail Investment</span>
            </p>
            <label style={{ fontWeight: "bold" }}>
              No hiddden cost Until you see revenue
            </label>
          </div>

          <div className="row" style={{ width: "70%", margin: "auto" }}>
            <div className="col-2 cols" align="right">
              <div>
                Pay as you grow
                <br />
                <span style={{ fontWeight: 700, fontSize: 12 }}>
                  No profit = No Cost
                </span>
              </div>
            </div>
            <div className="col">
              <ul className="timeline" id="timeline">
                <li className="li complete">
                  <div className="timestamp"></div>
                  <div className="status">
                    <h4> 30 </h4>
                  </div>
                </li>
                <li className="li complete">
                  <div className="timestamp"></div>
                  <div className="status">
                    <h4>100 </h4>
                  </div>
                </li>
                <li className="li complete">
                  <div className="timestamp"></div>
                  <div className="status">
                    <h4> 1000 </h4>
                  </div>
                </li>
                <li className="li">
                  <div className="timestamp">
                    {/* <span className="author">PAM Admin</span>
                  <span className="date">TBD</span> */}
                  </div>
                  <div className="status">
                    <h4> 20,000 </h4>
                  </div>
                </li>
              </ul>{" "}
            </div>
            <div className="col-1 cols">USD</div>
          </div>
          <Sponsor />
          <div className="  ">
            <div className=" "></div>
            <Vip />
          </div>
        </div>
      </div>
    </div>
  );
}
