import React, { useState } from "react";
import "./Styles.css";
import { Col, Row } from "react-bootstrap";

export default function Vip({ Vipnumber, userData, dataArray }) {
  const data = [
    {
      grade: 0,
      vip: "VIP 1",
      logo: "",
      desc: [
        "Suitable for most data capture scenarios involving light to medium usage",
        "Profit of 0.5% per task, you complete 45 tasks per set Up to 135 optimise ratings per day",
        "No access to other Premium features",
      ],
      price: 0,
    },
    {
      grade: 1,
      vip: "VIP 2",
      logo: "",
      desc: [
        "Deposit in accordance with our renewal event",
        "Profit of 1% per task, you complete 50 tasks per set Better Profit and permission",
        "Up to 150 tasks per day can be submitted by Vip 2 users",
      ],
      price: 100,
    },
    {
      grade: 2,
      vip: "VIP 3",
      logo: "",
      desc: [
        "Deposit in accordance with our renewal event",
        "Profit of 1.5% per task, you complete 55 tasks per set Better Profit and permission",
        "Up to 165 tasks per day can be submitted by Vip 3 users",
      ],
      price: 200,
    },
    {
      grade: 3,
      vip: "VIP 4",
      logo: "",
      desc: [
        "Deposit in accordance with our renewal event",
        "Profit of 2% per task, you complete 60 tasks per set Better Profit and permission",
        "Up to 300 tasks per day can be submitted by Vip 4 users",
      ],
      price: 300,
    },
  ];

  const [vip, setVip] = useState(0);
  const [price, setPrice] = useState(0);

  const mapDiv = data.map((a, b) => (
    <Col className="cloo" style={a.grade <= Vipnumber ? { opacity: 0.2 } : {}}>
      <div
        className="divdd"
        style={
          vip == a.grade
            ? { height: "100%", border: "2px solid red" }
            : { height: "100%", background: "none" }
        }
      >
        <div className="viptab" style={{ padding: 10, width: "40%" }}>
          {a.vip}
        </div>
        <div>
          <img src={a.logo} />
        </div>
        {a.desc.map((x, y) => (
          <div
            style={
              y == 1
                ? { color: "blueviolet", fontWeight: 500, fontSize: 13 }
                : {}
            }
          >
            {x}{" "}
          </div>
        ))}
      </div>
      <button
        onClick={() => {
          setPrice(a.price);
          setVip(a.grade);
        }}
      >
        Subscribe <span style={{ fontWeight: "bold" }}>{a.price}</span> USDT
      </button>
    </Col>
  ));

  return (
    <div style={{ width: "98%", margin: "auto" }}>
      <div className="row">
        <div className="col">
          <h2>VIP LEVELS</h2>
        </div>
        <div
          className="col"
          style={{ textAlign: "right", paddingRight: "16px" }}
        >
          {/* <span>View More</span> */}
        </div>
      </div>
      <div className="vip">
        <Row lg={4} md={2} xs={2}>
          {mapDiv}
        </Row>

        <hr />

        <div align="center">
          <h4>Confirm {price} USDT WILL BE DEDUCTED FROM WALLET</h4>

          <button
            className="btn approvedbtn"
            style={{}}
            onClick={() => userData(vip, price)}
          >
            APPROVED
          </button>
        </div>
        {/* <div className="row"></div> */}
      </div>
    </div>
  );
}
