import React, { useState } from "react";
import UserTable from "./UserManage";
import ProductTable from "./AddProduct";
import SmallHeader from "../../Header/SmallHeader";
import { useClientContext } from "../../../context/context";

export default function Admin() {
  const [active, setActive] = useState(1);
  const { loggedUser } = useClientContext();

  return (
    <div>
      <SmallHeader
        wallet={loggedUser?.TronBalance}
        Earnings={loggedUser?.earnings}
      />
      <div className="container" style={{ marginTop: "10vh" }}>
        <div className="row">
          <div className="col-12 col-md-3 col-lg-2 mb-3 mb-md-0">
            <div
              onClick={() => setActive(1)}
              style={
                active === 1
                  ? { padding: 10, background: "red", color: "white" }
                  : { padding: 10, background: "none" }
              }
              className="mb-2"
            >
              Products
            </div>
            <div
              onClick={() => setActive(0)}
              style={
                active === 0
                  ? { padding: 10, background: "red", color: "white" }
                  : { padding: 10, background: "none" }
              }
              className="mb-2"
            >
              Users
            </div>
          </div>
          <div className="col-12 col-md-9 col-lg-10">
            {active === 0 ? <UserTable /> : <ProductTable />}
          </div>
        </div>
      </div>
    </div>
  );
}
