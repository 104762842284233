import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  div,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Padding, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./login.css";
import { message, Modal } from "antd";
import { Api_url } from "../../../Api/Api";
import Cookies from "js-cookie";

function LoginUser() {
  axios.defaults.withCredentials = true;
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    Email: "",
    password: "",
    Username: "",
    Yourname: "",
    otp: "",
  });
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(true); // Set default to login mode
  const [errors, setErrors] = useState({
    Email: false,
    password: false,
    credentials: false,
    Username: false,
    Yourname: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [putCode, setPutCode] = useState(false); // Updated default to false

  const getData = (e) => {
    const { value, name } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateFields = () => {
    const newErrors = {
      Email: !formValues.Email,
      password: !formValues.password,
      Username: !login && !formValues.Username,
      Yourname: !login && !formValues.Yourname,
      credentials: false,
    };
    setErrors(newErrors);
    return (
      !newErrors.Email &&
      !newErrors.password &&
      (!login || (!newErrors.Username && !newErrors.Yourname))
    );
  };

  const loginData = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        login ? `${Api_url}/user/login` : `${Api_url}/user/createuser`,
        formValues
      );

      const users = response.data;
      Cookies.set("_id", users.userId);
      console.log("user", users);
      Modal.success({
        title: "Success",
        content: login
          ? "Login was successful!"
          : "Signup was successful! Please check your Email Address and confirm the code.",

        onOk: () => {
          if (!login) {
            setPutCode(true);
            setLogin(true);
          }
          setLoading(false);
        },
      });
      // If login is successful, you can redirect the user to the dashboard
      if (login) {
        navigate("/Dashboard");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error:", error);
      const errorMessage =
        error.response?.status === 500
          ? "Email Already Registered."
          : "An error occurred. Please try again.";
      message.error(errorMessage);
      setLoading(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        credentials: true,
      }));
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <div className="logopage">
      <div className="mainlogin">
        <Box
          className="boxlogin"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <h4
            style={{ textAlign: "center", fontFamily: "Dela Gothic One" }}
            className="BIGFONT"
          >
            {login ? "Sign In" : "Sign Up"}
          </h4>
          <Box component="form" onSubmit={loginData}>
            {!login && (
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="Username"
                  type="text"
                  label="Username"
                  name="Username"
                  autoComplete="username"
                  autoFocus
                  value={formValues.Username}
                  onChange={getData}
                  error={errors.Username || errors.credentials}
                  helperText={errors.Username ? "Username is required" : ""}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="Yourname"
                  type="text"
                  label="Your Name"
                  name="Yourname"
                  autoComplete="yourname"
                  autoFocus
                  value={formValues.Yourname}
                  onChange={getData}
                  error={errors.Yourname || errors.credentials}
                  helperText={errors.Yourname ? "Your name is required" : ""}
                />
              </>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="Email"
              type="email"
              label="Email Address"
              name="Email"
              autoComplete="email"
              autoFocus
              value={formValues.Email}
              onChange={getData}
              error={errors.Email || errors.credentials}
              helperText={
                errors.Email
                  ? "Email is required"
                  : errors.credentials
                  ? "Invalid Email or password"
                  : ""
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={formValues.password}
              onChange={getData}
              error={errors.password || errors.credentials}
              helperText={
                errors.password
                  ? "Password is required"
                  : errors.credentials
                  ? "Invalid Email or password"
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {putCode && (
              <TextField
                margin="normal"
                required
                fullWidth
                id="otp"
                type="text"
                label="Confirm Code"
                name="otp"
                autoComplete="otp"
                autoFocus
                value={formValues.otp}
                onChange={getData}
                error={errors.otp || errors.credentials}
                helperText={
                  errors.otp
                    ? "Your OTP is required"
                    : errors.credentials
                    ? "Invalid OTP"
                    : ""
                }
              />
            )}
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
              style={{ background: login ? "#00cdcc" : "red" }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : login ? (
                "Sign In"
              ) : (
                "Sign Up"
              )}
            </Button>
            {login && (
              <Grid>
                <Link href="">Forgot password?</Link>
              </Grid>
            )}
            <Grid className="footer">
              <Typography component="h5">
                {login ? "Don't have an account?" : "Already have an account?"}{" "}
                <span
                  onClick={() => {
                    setLogin(!login);
                    setPutCode(false); // Reset the OTP field visibility
                  }}
                  style={{ color: "#00cdcc", cursor: "pointer" }}
                >
                  {login ? "Sign Up" : "Login"}
                </span>
              </Typography>
            </Grid>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default LoginUser;
