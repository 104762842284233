import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import Login from "../pages/account/login/Login";
import Dashboard from "../pages/dashbaord/Dashboard/Dashboard";
import Header from "../components/Header/Header";
import Profile from "../pages/account/Profile";
import ProductTable from "../pages/dashbaord/Admin/AddProduct";
import Admin from "../pages/dashbaord/Admin/Admin";
import { ProtectedRoute } from "../Private/Private";
import Main from "../Main/Components/Main/Main";
import About from "../Main/Components/About/About";
import Office from "../Main/Components/Main/office.mp4";
// import Home from "../pages/home/Home";
export default function RoutesSection() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <div>
            <Main Office={Office} />
            <About />
          </div>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route
        path="/Dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Admin"
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
