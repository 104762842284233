import React from "react";

export default function Sponsor() {
  return (
    <div>
      <section className="sponsor section">
        <div className="sponsor__container">
          <div className="sponsor__content">
            <img
              src="https://github.com/bedimcode/responsive-travel-website/blob/main/assets/img/sponsors1.png?raw=true"
              alt=""
              className="sponsor__img"
            />
          </div>
          <div className="sponsor__content">
            <img
              src="https://github.com/bedimcode/responsive-travel-website/blob/main/assets/img/sponsors2.png?raw=true"
              alt=""
              className="sponsor__img"
            />
          </div>
          <div className="sponsor__content">
            <img
              src="https://github.com/bedimcode/responsive-travel-website/blob/main/assets/img/sponsors3.png?raw=true"
              alt=""
              className="sponsor__img"
            />
          </div>
          <div className="sponsor__content">
            <img
              src="https://github.com/bedimcode/responsive-travel-website/blob/main/assets/img/sponsors4.png?raw=true"
              alt=""
              className="sponsor__img"
            /> 
          </div>
          <div className="sponsor__content">
            <img
              src="https://github.com/bedimcode/responsive-travel-website/blob/main/assets/img/sponsors5.png?raw=true"
              alt=""
              className="sponsor__img"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
