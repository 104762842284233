import React, { useState } from "react";
import { Table, Button, Modal, Form, Input, message } from "antd";
import { useClientContext } from "../../../context/context"; // Ensure the path is correct
import { Api_url } from "../../../Api/Api";
import axios from "axios";

const UserTable = () => {
  const { users, updateUser, fetchDataUser } = useClientContext(); // Assuming you have an updateUser function in your context

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingUser, setEditingUser] = useState(null);

  const columns = [
    {
      title: "_id",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Completed",
      dataIndex: "completed",
      key: "completed",
      render: (completed) => completed.length, // Display number of completed items
    },
    // {
    //   title: "Address",
    //   dataIndex: "TronAddress",
    //   key: "TronAddress",
    // },
    {
      title: "Balance",
      dataIndex: "TronBalance",
      key: "TronBalance",
    },
    {
      title: "Market Balance",
      dataIndex: "marketBalance",
      key: "marketBalance",
      render: (text, record) => record.marketBalance?.toFixed(2), // Assuming marketBalance is calculated based on TronBalance
    },
    {
      title: "Reward",
      dataIndex: "earnings",
      key: "earnings",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditUser(record)}>
          Edit
        </Button>
      ),
    },
  ];

  const handleAddUser = () => {
    setEditingUser(null); // Clear the editing user state
    form.resetFields(); // Clear the form fields
    setIsModalVisible(true);
  };

  const handleEditUser = (user) => {
    setEditingUser(user); // Set the editing user state
    form.setFieldsValue(user); // Pre-fill the form with the selected user's data
    setIsModalVisible(true);
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        if (editingUser) {
          axios
            .put(`${Api_url}/user/${editingUser._id}`, values)
            .then((response) => {
              message.success("User Balance Modified");
              fetchDataUser();
              setIsModalVisible(false);
              form.resetFields();
            })
            .catch((error) => {
              console.error("Failed to update user:", error);
              message.error("Failed to update user.");
            });
        } else {
          axios
            .post(`${Api_url}/user`, values)
            .then((response) => {
              message.success("User Added");
              fetchDataUser();
              setIsModalVisible(false);
              form.resetFields();
            })
            .catch((error) => {
              console.error("Failed to add user:", error);
              message.error("Failed to add user.");
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <div style={{ padding: "20px" }}>
      <Button
        type="primary"
        onClick={handleAddUser}
        style={{ marginBottom: 16 }}
      >
        Add User
      </Button>
      <Table
        dataSource={users}
        columns={columns}
        rowKey="_id"
        scroll={{ x: "100%" }}
      />
      <Modal
        title={editingUser ? "Edit User" : "Add User"}
        visible={isModalVisible}
        onOk={handleSave}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="_id"
            label="ID"
            rules={[{ required: true, message: "Please input the ID!" }]}
          >
            <Input disabled={editingUser !== null} />
          </Form.Item>
          <Form.Item
            name="Yourname"
            label="Name"
            rules={[{ required: true, message: "Please input the name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Email"
            label="Email"
            rules={[
              { required: true, message: "Please input the email!" },
              { type: "email", message: "The input is not valid E-mail!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="TronBalance"
            label="Balance"
            rules={[{ required: true, message: "Please input the balance!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserTable;
