import React, { useState } from "react";
import { Table, Button, Modal, Form, Input, Space, message } from "antd";
import axios from "axios";
import { useClientContext } from "../../../context/context";
import { Api_url } from "../../../Api/Api";

const ProductTable = () => {
  const { products, setProducts } = useClientContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      // responsive: ["md"],
    },
    {
      title: "Product Label",
      dataIndex: "label",
      key: "label",
      // responsive: ["xs", "sm", "md", "lg", "xl"],
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      // responsive: ["sm", "md", "lg", "xl"],
    },
    {
      title: "Profits",
      dataIndex: "profits",
      key: "profits",
      // responsive: ["sm", "md", "lg", "xl"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // responsive: ["md", "lg", "xl"],
    },
    {
      title: "Image",
      dataIndex: "img",
      key: "img",
      render: (text) => <img src={text} alt="product" style={{ width: 50 }} />,
      // responsive: ["md", "lg", "xl"],
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="link" onClick={() => handleDelete(record)}>
            Delete
          </Button>
        </Space>
      ),
      // responsive: ["sm", "md", "lg", "xl"],
    },
  ];

  const handleAddProduct = () => {
    setIsEditMode(false);
    setCurrentProduct(null);
    setIsModalVisible(true);
  };

  const handleEdit = (record) => {
    setIsEditMode(true);
    setCurrentProduct(record);
    setIsModalVisible(true);
    form.setFieldsValue(record);
  };

  const handleDelete = (record) => {
    setProducts(products.filter((product) => product.label !== record.label));
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (isEditMode) {
          // Update product locally
          setProducts(
            products.map((product) =>
              product.label === currentProduct.label ? values : product
            )
          );
        } else {
          console.log("valuesvalues", values);
          // Post new product to the API
          axios
            .post(`${Api_url}/products`, values)
            .then((response) => {
              setProducts([...products, response.data]);
              setIsModalVisible(false);
              form.resetFields();
              message.success("Product added successfully!");
            })
            .catch((error) => {
              console.error("There was an error adding the product!", error);
              message.error("Failed to add product.");
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={handleAddProduct}
        style={{ marginBottom: 16 }}
      >
        Add Product
      </Button>
      <Table
        dataSource={products}
        columns={columns}
        rowKey="label"
        scroll={{ x: 800 }}
      />
      <Modal
        title={isEditMode ? "Edit Product" : "Add Product"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="50%"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="label"
            label="Product Label"
            rules={[
              { required: true, message: "Please input the product label!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: "Please input the price!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="profits"
            label="Profits"
            rules={[{ required: true, message: "Please input the profits!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: "Please input the status!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="img"
            label="Image URL"
            rules={[{ required: true, message: "Please input the image URL!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProductTable;
