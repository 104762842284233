import React, { useState } from "react";
import { Button, Modal, Box, Typography, Container, Grid } from "@mui/material";

const Profile = () => {
  const [openModal, setOpenModal] = useState(null);

  const handleOpen = (section) => () => {
    setOpenModal(section);
  };

  const handleClose = () => {
    setOpenModal(null);
  };

  const sections = {
    Transactions: ["Deposit", "Withdraw"],
    "Profile and Account": [
      "Profile",
      "Change Password",
      "Bind Wallet Address",
      "Account",
      "Contact Us",
      "Log Out",
    ],
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard
      </Typography>

      {Object.keys(sections).map((heading) => (
        <div key={heading}>
          <Typography variant="h5" component="h2" gutterBottom>
            {heading}
          </Typography>
          <Grid container spacing={2}>
            {sections[heading].map((section) => (
              <Grid item xs={12} sm={6} md={4} key={section}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleOpen(section)}
                >
                  {section}
                </Button>
              </Grid>
            ))}
          </Grid>
        </div>
      ))}

      {Object.keys(sections).flatMap((heading) =>
        sections[heading].map((section) => (
          <Modal
            open={openModal === section}
            onClose={handleClose}
            key={section}
            aria-labelledby={`${section}-title`}
            aria-describedby={`${section}-description`}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography id={`${section}-title`} variant="h6" component="h2">
                {section}
              </Typography>
              <Typography id={`${section}-description`} sx={{ mt: 2 }}>
                This is the {section.toLowerCase()} modal content.
              </Typography>
            </Box>
          </Modal>
        ))
      )}
    </Container>
  );
};

export default Profile;
