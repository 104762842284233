import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Style.css";

import logo from "../../assets/logo.png";
import { useClientContext } from "../../context/context";
import { Link } from "react-router-dom";

export default function SmallHeader({ wallet, Earnings }) {
  const { logout } = useClientContext();
  return (
    <div>
      <div className="headerbgup">
        <div className="container">
          <Row className="align-items-center">
            <Col xs={4} md={4}>
              <Link to="/">
                <img src={logo} height={"40px"} alt="Logo" />
              </Link>
            </Col>
            <Col xs={4} md={4} className="d-none d-md-block text-end">
              <label>
                <Link
                  to="/Dashboard"
                  style={{ color: "white", paddingRight: 15 }}
                >
                  BUY PRODUCTS
                </Link>
              </label>
              <label>
                Wallet:{" "}
                <span style={{ color: "red" }}>{wallet?.toFixed(2)}</span> USD
              </label>
              <label style={{ paddingLeft: "10px" }}>
                Earnings:{" "}
                <span style={{ color: "red" }}>{Earnings?.toFixed(2)}</span> USD
              </label>
            </Col>
            <Col xs={4} className="d-block d-md-none text-end">
              <label>
                Wallet: <span style={{ color: "red" }}>{wallet}</span> USD
              </label>

              <label>
                Earnings: <span style={{ color: "red" }}>{Earnings}</span> USD
              </label>
            </Col>
            <Col
              xs={4}
              md={4}
              className="text-end"
              style={{ color: "red" }}
              align="right"
            >
              <span onClick={logout} style={{ cursor: "pointer" }}>
                LOG OUT
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
