import React, { useState } from "react";
import { Col, Row, ButtonGroup, Button } from "react-bootstrap";
import QRCode from "qrcode.react";
import { useClientContext } from "../../../context/context";

export default function Wallet({ wallet }) {
  const { loggedUser } = useClientContext();

  console.log("logged", loggedUser);

  const [network, setNetwork] = useState("tron");

  const addresses = {
    tron: loggedUser?.TronAddress,
    tron: "TJ3Fso348CXwNzWP6HsXTEnvrP8Wrs33oH",
    eth: "0x0d0b5f912f49ae38428a4d87c4ab64f2b088422b",
  };

  const handleNetworkChange = (network) => {
    setNetwork(network);
  };

  return (
    <div align="center">
      <QRCode value={addresses[network]} size={100} />
      <hr />
      <span style={{ fontWeight: "bold" }}>
        BALANCE : {wallet.toFixed(2)} USDT
      </span>
      <hr />

      <ButtonGroup>
        <Button
          variant={network === "tron" ? "primary" : "outline-primary"}
          onClick={() => handleNetworkChange("tron")}
        >
          Tron
        </Button>
        <Button
          variant={network === "eth" ? "primary" : "outline-primary"}
          onClick={() => handleNetworkChange("eth")}
        >
          Ethereum
        </Button>
      </ButtonGroup>

      <hr />
      <span style={{ color: "red" }}>
        Deposit USDT(TRC20) OR ERC20
      </span>

      <Row>
        <Col>
          Wallet Address: <br/> <b>{addresses[network]}</b>
          <br />
          Network:{" "}
          <span style={{ color: "brown", fontWeight: "bold" }}>
            {network === "tron" ? "Tron Network" : "Ethereum Network"}
          </span>
        </Col>
      </Row>
      <span style={{ color: "red" }}>
        Use the copied address during the transfer from your personal USDT ERC20 or TRC20 for each
        next deposit, Copy the address again.        <hr />
        <b>
          <p>This is an approximate rate it may change by the time the transaction is processed</p>
        </b>
        <hr />
      </span>
    </div>
  );
}
