import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./cards.css";
import ProductModal from "./ProductCard";
import { useClientContext } from "../../../../context/context";

export default function Cards({
  walletBalance,
  userx,
  onCompleteTask,
  tabselected,
}) {
  const [show, setShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { products, setProducts } = useClientContext();
  const handleClose = () => setShow(false);
  const handleShow = (product, index) => {
    setSelectedProduct({ product, index });
    setShow(true);
  };
  const handleBuyNow = (a, b) => {
    onCompleteTask(selectedProduct.index, a, b);
    handleClose();
  };

  const task = products;
  const divTaskAll = task.map((a, b) => {
    // const isCompleted = userx.completed.includes(b);
    const isCompleted = userx.completed.some((item) => item.index === a._id);

    return (
      <Col key={b}>
        <ul className="cardul">
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={a.img} height="100%" alt={a.label} />
          </li>
          <li>
            <span> {a.createdAt}</span>
            <div
              style={{
                height: "60px",
                fontWeight: 800,
                borderBottom: "1px solid lightgrey",
              }}
            >
              {a.label}
            </div>
            <br />
            <div className="row">
              <div className="col">
                <div>
                  Price : <label className="price">$ {a.price}</label>
                </div>
                <div>
                  Profits : <label className="price">$ {a.profits}</label>
                </div>
              </div>
              <div className="col">
                {isCompleted ? (
                  <button
                    className="btn btn-warning"
                    style={{
                      background: "green",
                      color: "white",
                      border: "none",
                    }}
                  >
                    COMPLETED
                  </button>
                ) : (
                  <button
                    className="btn btn-warning"
                    onClick={() => handleShow(a, a._id)}
                  >
                    BUY NOW
                  </button>
                )}
              </div>
            </div>
          </li>
        </ul>
      </Col>
    );
  });
  const divTaskCompleted = task.map((a, b) => {
    const isCompleted = userx.completed.some((item) => item.index === a._id);
    return (
      <Col key={b} style={!isCompleted ? { display: "none" } : {}}>
        <ul className="cardul">
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={a.img} height="100%" alt={a.label} />
          </li>
          <li>
            <span> {a.date}</span>
            <div
              style={{
                height: "60px",
                fontWeight: 800,
                borderBottom: "1px solid lightgrey",
              }}
            >
              {a.label}
            </div>
            <br />
            <div className="row">
              <div className="col">
                <div>
                  Price : <label className="price">{a.price}</label>
                </div>
                <div>
                  Profits : <label className="price">{a.profits}</label>
                </div>
              </div>
              <div className="col">
                {isCompleted ? (
                  <button
                    className="btn btn-warning"
                    style={{
                      background: "green",
                      color: "white",
                      border: "none",
                    }}
                  >
                    COMPLETED
                  </button>
                ) : (
                  <button
                    className="btn btn-warning"
                    onClick={() => handleShow(a, a._id)}
                  >
                    BUY NOW
                  </button>
                )}
              </div>
            </div>
          </li>
        </ul>
      </Col>
    );
  });
  const divTaskPending = task.map((a, b) => {
    // const isCompleted = userx.completed.includes(b);
    const isCompleted = userx.completed.some((item) => item.index === a._id);

    return (
      <Col key={b} style={isCompleted ? { display: "none" } : {}}>
        <ul className="cardul">
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={a.img} height="100%" alt={a.label} />
          </li>
          <li>
            <span> {a.date}</span>
            <div
              style={{
                height: "60px",
                fontWeight: 800,
                borderBottom: "1px solid lightgrey",
              }}
            >
              {a.label.slice(0, 120)}

              {a.label.length > 120 ? "......." : ""}
            </div>
            <br />
            <div className="row">
              <div className="col">
                <div>
                  Price : <label className="price">{a.price}</label>
                </div>
                <div>
                  Profits : <label className="price">{a.profits}</label>
                </div>
              </div>
              <div className="col">
                {isCompleted ? (
                  <button
                    className="btn btn-warning"
                    style={{
                      background: "green",
                      color: "white",
                      border: "none",
                    }}
                  >
                    COMPLETED
                  </button>
                ) : (
                  <button
                    className="btn btn-warning"
                    onClick={() => handleShow(a, a._id)}
                  >
                    BUY NOW
                  </button>
                )}
              </div>
            </div>
          </li>
        </ul>
      </Col>
    );
  });

  return (
    <div>
      {/* <h3>{tabselected}</h3> */}
      <Row md={2} xs={1} lg={3} className="g-2">
        {tabselected == 0
          ? divTaskAll
          : tabselected == 1
          ? divTaskPending
          : divTaskCompleted}
      </Row>
      {selectedProduct && (
        <ProductModal
          show={show}
          handleClose={handleClose}
          product={selectedProduct.product}
          handleBuyNow={handleBuyNow}
          walletBalance={walletBalance}
        />
      )}
    </div>
  );
}
