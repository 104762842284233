import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Api_url } from "../Api/Api";

const ClientContext = createContext();
export const ClientProvider = ({ children }) => {
  axios.defaults.withCredentials = true;
  const [loggedUser, setLogged] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUser] = useState([]);
  const [products, setProducts] = useState([]);
  const [id, setId] = useState(Cookies.get("_id") || null);

  const fetchDataUser = async () => {
    try {
      // console.log("id", id);
      const userResponse = await axios.get(`${Api_url}/user/oneuser`);
      setLogged(userResponse.data);
      console.log(userResponse.data);
      const userAllResponse = await axios.get(`${Api_url}/user`);
      setUser(userAllResponse.data);
      console.log("User", userAllResponse.data);
      const productResponse = await axios.get(`${Api_url}/products`);
      setProducts(productResponse.data);
    } catch (error) {
      // console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchData = async () => {
    try {
    } catch (error) {
      console.error("Error fetching data:", error.response);
    }
  };

  const logout = async () => {
    // Cookies.remove("access_token", { secure: true, sameSite: "None" });
    Cookies.remove("_id", { secure: true, sameSite: "None" });
    setId(null);
  };

  useEffect(() => {
    // fetchDataTest();
    fetchDataUser();
    fetchData();
  }, []);

  return (
    <ClientContext.Provider
      value={{
        users,
        products,
        loggedUser,
        fetchData,
        logout,
        id,
        setProducts,
        fetchDataUser,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () => useContext(ClientContext);
