import React from "react";
import "./About.css";

export default function About() {
  return (
    <div>
      <section className="section_all bg-light" id="about">
        {/* <div className="backLetter">ABOUT US</div> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_title_all text-center">
                <div className="heading" align="center">
                  <label style={{ marginRight: "30px" }}></label>
                  <span data-aos="zoom-in-left">About Us</span>
                  <label style={{ marginLeft: "30px" }}></label>
                </div>

                <br />
                <br />
                <p
                  className="section_subtitle mx-auto text-muted goldcolor"
                  data-aos="zoom-in-left"
                  style={{ fontWeight: 900 }}
                >
                  WE ARE ACCUMULATOR
                </p>
                <div className="">
                  <i className=""></i>
                </div>
              </div>
            </div>
          </div>

          <div className="row vertical_content_manage mt-5">
            <div className="col-lg-6">
              <div className="about_header_main mt-3">
                <div className="about_icon_box">
                  <p
                    className="text_custom font-weight-bold goldcolor"
                    data-aos="zoom-in-left"
                    style={{ color: "#be8f2d" }}
                  >
                    We offer a wide range of bonuses on shopping for our
                    customers.
                  </p>
                </div>
                <h4
                  className="about_heading text-capitalize font-weight-bold mt-4"
                  data-aos="zoom-in-left"
                >
                  We began offering extensive bonuses and rewards on shopping to
                  our customers, driven by their demand for more value.
                </h4>
                <p className="text-muted mt-3" data-aos="zoom-in-left">
                  We are passionate about providing flexible and customized
                  rewards where every customer is valued equally, regardless of
                  their shopping volume. We ensure consistent quality in our
                  services through regular checks and a tested process delivered
                  by our skilled team.
                </p>

                <p className="text-muted mt-3" data-aos="zoom-in-left">
                  Our expertise includes offering bonuses across various
                  categories such as electronics, fashion, groceries, and more.
                  We aim to provide quality services because we believe that
                  rewarding shopping experiences benefit both physical and
                  mental well-being.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img_about mt-3">
                <img
                  src="https://assets.aboutamazon.com/dims4/default/6fbfec9/2147483647/strip/false/crop/2000x1125+0+0/resize/1486x836!/quality/90/?url=https%3A%2F%2Famazon-blogs-brightspot.s3.amazonaws.com%2F9c%2F7b%2F5e02715f4a97b4c27100614cafc2%2Fhero3.jpg"
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-4">
              <div className="about_content_box_all mt-3">
                <div className="about_detail text-center">
                  <div className="about_icon">
                    <i
                      className="fas fa-pencil-alt"
                      style={{ color: "green" }}
                    ></i>
                  </div>
                  <h5
                    className="text-dark text-capitalize mt-3 font-weight-bold goldcolor"
                    data-aos="zoom-in-left"
                  >
                    Our Mission
                  </h5>
                  <p
                    className="edu_desc mt-3 mb-0 text-muted"
                    data-aos="zoom-in-left"
                  >
                    To provide rewarding shopping experiences that exceed
                    expectations in terms of value, cost-effectiveness, and
                    customer satisfaction.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="about_content_box_all mt-3">
                <div className="about_detail text-center">
                  <div className="about_icon">
                    <i
                      className="fab fa-angellist zracolor"
                      style={{ color: "green" }}
                    ></i>
                  </div>
                  <h5
                    className="text-dark text-capitalize mt-3 font-weight-bold goldcolor"
                    data-aos="zoom-in-left"
                  >
                    Our Vision
                  </h5>
                  <p
                    className="edu_desc mb-0 mt-3 text-muted"
                    data-aos="zoom-in-left"
                  >
                    To meet the growing demand for rewarding shopping
                    experiences with the latest technology innovations to
                    satisfy our customers' needs.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="about_content_box_all mt-3">
                <div className="about_detail text-center">
                  <div className="about_icon">
                    <i
                      className="fas fa-paper-plane"
                      style={{ color: "green" }}
                    ></i>
                  </div>
                  <h5
                    className="text-dark text-capitalize mt-3 font-weight-bold goldcolor"
                    data-aos="zoom-in-left"
                  >
                    Our Objective
                  </h5>
                  <p
                    className="edu_desc mb-0 mt-3 text-muted"
                    data-aos="zoom-in-left"
                  >
                    To ensure our customers receive the best bonuses and rewards
                    in the shopping industry, providing a premium and luxurious
                    experience that exceeds expectations.
                    <br />
                    <br />
                    <div
                      align="right"
                      className="zracolor"
                      data-aos="zoom-in-left"
                    >
                      <label>Learn More</label>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
