import React from "react";
import "./Main.css";
import { Link } from "react-router-dom";

export default function Main(props) {
  return (
    <div className="Main">
      <div className="container">
        <video autoPlay loop muted id="video">
          <source src={props.Office} type="video/mp4" />
        </video>
        <p data-aos="fade-up">We bring the best for you</p>
        <h3 data-aos="fade-up">
          Accumulator was set-up <br />
          as a rewards company offering bonuses
          <br /> on shopping for our customers.
        </h3>
        <div>
          <Link to="/Dashboard">
            <button data-aos="fade-up">REGISTER</button>
          </Link>
          <Link to="/Dashboard">
            <button data-aos="fade-up">LOG IN</button>
          </Link>
        </div>
        <div className="floatDescription">
          <div className="row">
            <div className="col">
              <h4 data-aos="fade-up">
                Our areas of expertise include providing bonuses in various
                categories such as electronics, fashion, groceries, and more.
              </h4>
              <p data-aos="fade-up">
                At Accumulator, we understand that establishing positive and
                effective leadership is a key factor in providing our customers
                with excellent services. We also implement best practices in
                customer satisfaction and service quality that have been honed
                through both experience and knowledge.
              </p>
            </div>
            <div className="col-3">
              <br />
              <br />
              <button data-aos="fade-up">DASHBOARD</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
