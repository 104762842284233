import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import RoutesSection from "./routes";
import Header from "./components/Header/Header";

function App() {
  return (
    <div className="">
      <Header />
      <RoutesSection />
    </div>
  );
}

export default App;
