import { Navigate } from "react-router-dom";
import { useClientContext } from "../context/context";
// import { useClientContext } from "../context/Context";

export const ProtectedRoute = ({ children }) => {
  const { id } = useClientContext();
  console.log("id", id);
  if (!id) {
    return <Navigate to="/login" />;
  }
  return children;
};
