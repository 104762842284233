import React from "react";
import Logo from "../../assets/logo.png";
import "./styles.css";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

export default function Header() {
  return (
    <div className="header" style={{ display: "none" }}>
      <div className="container">
        <div className="row">
          <div className="col-8">
            <img src={Logo} />
          </div>
          <div className="col">
            <ul>
              <li>Home</li>
              <li>Account</li>
              <li>
                <HeadsetMicIcon />
              </li>
            </ul>
          </div>
        </div>
        {/* <h1>his is</h1> */}
      </div>
    </div>
  );
}
