import { message } from "antd";
import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function ProductModal({
  show,
  handleClose,
  product,
  handleBuyNow,
  walletBalance,
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Product Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div align="center">
          <img src={product.img} alt={product.label} style={{ width: "40%" }} />
          <h4 style={{ fontSize: "15px", fontWeight: "bold" }}>
            {product.label}
          </h4>
          <p>
            Price: <b>$ {product.price}</b>
          </p>
          <p>
            Profits: <b>$ {product.profits}</b>
          </p>
          <hr />
          <b>
            Wallet :
            <span style={{ fontSize: "16px", color: "brown" }}>
              {walletBalance?.toFixed(2)} USDT
            </span>{" "}
          </b>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (walletBalance > product.price) {
              handleBuyNow(product.price, product.profits);
            } else {
              message.error("Please Recharge To Claim");
              return;
            }
          }}
        >
          BUY NOW
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
