import React, { useEffect, useState } from "react";
import "./Task.css";
import Cards from "./Cards/Cards";
import { Col, Row } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import Vip from "../Vip/Vip";

import Wallet from "../Wallet/Wallet";
import { Link } from "react-router-dom";
import SmallHeader from "../../Header/SmallHeader";
import { useClientContext } from "../../../context/context";
import axios from "axios";
import { message } from "antd";
import { Api_url } from "../../../Api/Api";


export default function Task() {
  axios.defaults.withCredentials = true;

  const { loggedUser, fetchDataUser } = useClientContext();
  
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [showwith, setShowwith] = useState(false);
  const [tab, setTab] = useState(0);
  const [userx, setUserx] = useState({
    username: "",
    _id: "",
    email: "",
    level: 0,
    wallet: 0,
    earnings: 0,
    completed: [],
    // pending: [],
  });

  useEffect(() => {
    if (loggedUser) {
      const datax = {
        _id: loggedUser?._id,
        username: loggedUser?.Username,
        email: loggedUser?.Email,
        wallet: loggedUser?.TronBalance,
        earnings: loggedUser?.earnings,
        level: 0,
        completed: loggedUser?.completed,
        // pending: [],
      };
      setUserx(datax);
    }
  }, [loggedUser]);
  const updateState = (level, price) => {
    setUserx((prevUserx) => ({
      ...prevUserx,
      level,
      wallet: (Number(prevUserx.wallet) - Number(price)).toFixed(2),
    }));
  };
  const handleTaskCompletion = (taskIndex, a, b) => {
    const currentTime = new Date().toISOString();
    const newCompletedTask = { time: currentTime, index: taskIndex };
    axios
      .post(`${Api_url}/user/complete`, newCompletedTask)
      .then((response) => {
        message.success("Success!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("There was an error adding the product!", error);
        message.error("Failed to add product.");
      });
  };

 
    const handleSubmit = (e) => {
      e.preventDefault(); // Prevent the form from submitting and refreshing the page
      alert("Request Submitted for Processing"); // Show the alert message
    };


  return (
    <div>
      <SmallHeader wallet={userx?.wallet} Earnings={userx?.earnings} />
      <div className="container tsk" style={{ marginTop: "8vh" }}>
        <Row md={2} xs={1} lg={2} className="g-2">
          <Col>
            <div className="text-center">
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/005/544/718/small/profile-icon-design-free-vector.jpg"
                alt="Profile"
                className="img-fluid"
              />
              <label className="d-block mt-2">
                Username: <b>{userx?.username}</b>
              </label>
              <span className="d-block mt-1">
                <Link to="/Edit:Profile" className="text-success fw-bold">
                  Edit Profile
                </Link>
              </span>
              <div className="mt-2">
                <label>Membership: VIP {userx.level}</label>
                <br />
                <button
                  className="btn btn-warning mt-2"
                  style={{ background: "#ffb02c", fontSize: "14px" }}
                  onClick={() => setShow(true)}
                >
                  UPGRADE
                </button> &nbsp;&nbsp;&nbsp;
                <button
                  className="btn btn-warning mt-2"
                  style={{ background: "#07e6ee", fontSize: "14px" }}
                  onClick={() => setShowwith(true)}>
                 Withdraw
                </button>
              </div>
            </div>
          </Col>
          <Col>
            <div className="sidecol">
              <div className="tsktab mb-3">
                <ul>
                  <li>
                    <h4>Total Earnings</h4>
                    <p>All earnings are dynamically updated</p>
                    <br />
                    <div className="">
                      <button className="btn btn-link p-0">
                        {userx?.earnings} USDT <br /> View Records
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tsktab">
                <ul>
                  <li>
                    <h4>Total Balance</h4>
                    <p>
                      Profits earned from each task will be added to the total
                      asset balance
                    </p>
                    <div className=" ">
                      <div className=" ">
                        <button
                          className="btn btn-link p-0"
                          onClick={() => setShow2(true)}
                        >
                          {userx?.wallet?.toFixed(2)} USDT <br />
                          View Wallet
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <div className="fixedtop">
          <div className="topbar">
            <hr />
            <div className="row" style={{ width: "85%", margin: "auto" }}>
              <div className="col">
                <h3>All Tasks</h3>
              </div>
              <div className="col" align="right">
                <span style={{ color: "#af7005" }}>View all</span>
              </div>
            </div>
            <div className="menu">
              <ul>
                <li
                  className={tab === 0 ? "active" : "normal"}
                  onClick={() => setTab(0)}
                >
                  All
                </li>
                <li
                  className={tab === 1 ? "active" : "normal"}
                  onClick={() => setTab(1)}
                >
                  Pending
                </li>
                <li
                  className={tab === 2 ? "active" : "normal"}
                  onClick={() => setTab(2)}
                >
                  Completed
                </li>
              </ul>
            </div>
          </div>
        </div>

        <br />
        <div className="taskbar">
          <Cards
            userx={userx}
            walletBalance={userx?.wallet}
            onCompleteTask={handleTaskCompletion}
            tabselected={tab}
          />
        </div>
        <div className="bottomfixed">
          <div className="container">
            <div className="row" align="center">
              <div className="col" align="right">
                <label>Portal</label>
                <p>Working hrs 24/7</p>
              </div>
              <div className="col">
                {userx.email == "yigasteven81@gmail.com" ||
                userx.email == "okurut17@gmail.com" ? (
                  <Link to="/Admin">
                    <button className="btn">Admin Panel</button>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={() => setShow(false)} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Upgrade Membership</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Vip
              Vipnumber={userx.level}
              userData={updateState}
              dataArray={userx}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => setShow(false)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show2} onHide={() => setShow2(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Wallet wallet={userx?.wallet} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow2(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showwith} onHide={() => setShowwith(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Withdraw Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
    
          <form >
    <div>
      <label style={{ fontSize: "16px"  }} >
        Wallet Address:<br/>
        <input type="text" name="username"  placeholder="Enter Wallet Address" 
        style={{ color: "red", borderRadius: "5px", width: "300px", height: "35px",  
        border: "1px solid #E6E6E6", boxSizing: "border-box", fontSize: "14px"   }}
          required
        />
      </label>
    </div>
    <div>
    <br/>
      <label style={{ fontSize: "16px"  }}>
        Amount:<br/>
        <input type="text" name="amount" placeholder="Enter Amount" 
         style={{ color: "red", borderRadius: "5px", width: "300px", height: "35px",
         border: "1px solid #E6E6E6", boxSizing: "border-box", fontSize: "14px"    }}
         required
        />
      </label>
      <br/><br/>
    </div>
      <input type="submit" onClick={handleSubmit} value="Submit Request" style={{ borderRadius: "5px", 
      width: "300px", height: "38px",
         border: "1px solid #E6E6E6", 
         boxSizing: "border-box", Color: "#fff", backgroundColor: "#befb79", fontSize: "16px"  }}  />
    </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowwith(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
